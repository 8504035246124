@import url('https://fonts.googleapis.com/css2?family=Ultra&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;1,900&display=swap');

.slider > img {
    height: auto;
    width: auto;
    object-fit: contain;
    margin-bottom: 15px;
}

.slider{
    
    width: 80%;
    margin: auto;
    object-fit: contain;
    background-color: #272727;
}

.slider__imgwrap {
    width: 100%;
    height: 600px;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0;
    align-items: center;
    z-index: 100;
  }
  
  .slider__imgwrap > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    margin-bottom: -200px;
    z-index: 100;
    /*mask-image: linear-gradient(to bottom,rgb(255,255,255,0),rgb(255,255,255,1));*/
    /*filter: blur(1px);*/
    /*background: linear-gradient(rgb(0,0,0,0.5),rgb(0,0,0,0.5)), url("images/momos.jpeg");*/
  }

  .slider__imgwrap > span > button {
    z-index: 100;
    width: 10%;
    opacity: 0.8;
    backdrop-filter: blur(15px);
    background-color:#FF652F;
    font-style: italic;
    font-family: 'Segoe UI';
    font-weight: 500;
    font-size: 4vh;
  }

  .slider__imgwrap > span {
    z-index: 100;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-style: italic;
    opacity: 1;
    backdrop-filter: blur(15px);
    width: 100%;
    height: auto;
    text-align: center;
    text-justify: auto;     
    vertical-align: middle;                                                                                                                                                                                                                                                                                                                                         ;
    font-size: 5vh;
    padding: 2vh;
  }

  .carousel_pagingDotsContainer{
      border-color: aqua;
  }

  .carousel_pagingDots{
    margin: 10px;
    border: none;
  }