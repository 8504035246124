@import url('https://fonts.googleapis.com/css2?family=Ultra&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Montserrat;
}
:root {
  --primaryColor: #af9a7d;
  --mainWhite: rgb(255, 255, 255);
  --offWhite: #f7f7f7;
  --mainBlack: #222;
  --mainGrey: #ececec;
  --darkGrey: #e4f7ff;
  --mainTransition: all 0.3s linear;  
  --mainSpacing: 3px;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
  --blues: #7ae2fc9c;
  /* --backcolor: #272727;
  --fontcolor: #14A76C; */
  --backcolor: #000000;
  --fontcolor: #856b39;
  --menucolor: #000000;
}
/* globals */
body {
  padding-top: 66px;
  color: var(--fontcolor);
  background: var(--mainWhite);
  font-family: 'Montserrat', sans-serif;
  line-height: 30px;
  color: #cccccc;
}
h1 {
  font-size: 3em;
  line-height: 1;
  margin-bottom: 0.5em;
  color: #856b39;
  font-family: 'Ultra', serif;
}
h2 {
  font-size: 2em;
  margin-bottom: 0.75em;
  color: #856b39;
  font-family: 'Ultra', serif;
}
h3 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 1em;
  color: #856b39;
  font-family: 'Ultra', serif;
}
h4 {
  font-size: 1.2em;
  line-height: 1.25;
  margin-bottom: 1.25em;
  color: #856b39;
  font-family: 'Ultra', serif;
}
h5 {
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 1.5em;
  color: #856b39;
  font-family: 'Ultra', serif;
}
h6 {
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 1.5em;
  color: #856b39;
  font-family: 'Ultra', serif;
}
.h7 {
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 0.5em;
  color: #856b39;
  font-family: 'Ultra', serif;
}

.btn-primary {
  display: inline-block;
  text-decoration: none;
  letter-spacing: var(--mainSpacing);
  color: var(--mainBlack);
  background: var(--primaryColor);
  padding: 0.4rem 0.9rem;
  border: 3px solid var(--primaryColor);
  transition: var(--mainTransition);
  text-transform: uppercase;
  cursor: pointer;
}
.btn-primary:hover {
  background: transparent;
  color: var(--primaryColor);
}
.loading {
  text-transform: capitalize;
  text-align: center;
  margin-top: 3rem;
}
.error {
  text-align: center;
  text-transform: uppercase;
  margin: 2rem 0;
}
.empty-search {
  text-align: center;
  text-transform: capitalize;
  margin: 2rem 0;
  padding: 1rem;
  letter-spacing: var(--mainSpacing);
}
/* end of globals */
/* Navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.75rem 2rem;
  background: var(--menucolor);
  z-index: 1;
}
.nav-header {
  display: flex;
  justify-content: space-between;
}
.nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}
.nav-icon {
  font-size: 1.5rem;
  color: var(--primaryColor);
}
.nav-links {
    height: 0;
    overflow: hidden;
  transition: var(--mainTransition);
  list-style-type: none;
  align-items: right;
}
.nav-links a {
  display: block;
  text-decoration: none;
  padding: 1rem 0;
  color: var(--mainBlack);
  transition: var(--mainTransition);
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: var(--mainSpacing);
}
.nav-links a:hover {
  color: var(--primaryColor);
}

.show-nav {
  height: Auto;
}
@media screen and (min-width: 1180px) {
  .nav-btn {
    display: none;
  }
  .nav-center {
    max-width: 1180px;
    margin: 0 auto;
    display: flex;
  }
  .nav-links {
    height: auto;
    display: flex;
    margin-left: 4rem;
    align-items: center;
  }
  .nav-links a {
    margin: 0 1rem;
    padding: 0.5rem 0;
  }
}
/* end of navbar */
/* Hero */
.defaultHero,

.gaslHero {
  min-height: calc(100vh - 66px);
  background: url("./images/GreenLivingWeb.webp") center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gaslHero {
  background-image: url("./images/GreenLivingWeb.webp");
  min-height: 60vh;
}
.coworkLargeHero {
  min-height: calc(100vh - 66px);
  background: url("./images/CoworkLargeWeb.webp") center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.coworkLargeHero {
  background-image: url("./images/CoworkLargeWeb.webp");
  min-height: 60vh;
}
.huhytHero {
  min-height: calc(100vh - 66px);
  background: url("./images/GreenandSustainableLiving.webp") center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.huhytHero {
  background-image: url("./images/GreenandSustainableLiving.webp");
  min-height: 40vh;
}
.hostelHero {
  min-height: calc(100vh - 66px);
  background: url("./images/HostelWeb.webp") center no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hostelHero {
  background-image: url("./images/HostelWeb.webp");
  min-height: 40vh;
}
.coworkHero {
  min-height: calc(100vh - 66px);
  background: url("./images/coworkWeb.webp") center no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.coworkHero {
  background-image: url("./images/coworkWeb.webp");
  min-height: 40vh;
}
.productHero {
  min-height: calc(100vh - 66px);
  background: url("./images/ProductWeb.webp") center no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.productHero {
  background-image: url("./images/ProductWeb.webp");
  min-height: 40vh;
}
.cafeHero {
  min-height: calc(100vh - 66px);
  background: url("./images/CafeWeb.webp") center no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cafeHero {
  background-image: url("./images/CafeWeb.webp");
  min-height: 40vh;
}
.movienightHero {
  min-height: calc(100vh - 66px);
  background: url("./images/MovieNightWeb.webp") center no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.movienightHero {
  background-image: url("./images/MovieNightWeb.webp");
  min-height: 40vh;
}
.marketHero {
  min-height: calc(100vh - 66px);
  background: url("./images/MarketWeb.webp") center no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.marketHero {
  background-image: url("./images/MarketWeb.webp");
  min-height: 40vh;
}
.bbqHero {
  min-height: calc(100vh - 66px);
  background: url("./images/BBQWeb.webp") center no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bbqHero {
  background-image: url("./images/BBQWeb.webp");
  min-height: 40vh;
}
.yogaHero {
  min-height: calc(100vh - 66px);
  background: url("./images/YogaWeb.webp") center no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.yogaHero {
  background-image: url("./images/YogaWeb.webp");
  min-height: 40vh;
}
.tentSmallHero {
  min-height: calc(100vh - 66px);
  background: url("./images/TentSmallWeb.webp") center no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tentSmallHero {
  background-image: url("./images/TentSmallWeb.webp");
  min-height: 40vh;
}
.dormSmallHero {
  min-height: calc(100vh - 66px);
  background: url("./images/DormSmall2Web.webp") center no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dormSmallHero {
  background-image: url("./images/DormSmall2Web.webp");
  min-height: 40vh;
}
.PRSmallHero {
  min-height: calc(100vh - 66px);
  background: url("./images/PRSmall1Web.webp") center no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.PRSmallHero {
  background-image: url("./images/PRSmall1Web.webp");
  min-height: 40vh;
}
.coWorkSmall1Hero {
  min-height: calc(100vh - 66px);
  background: url("./images/CoworkSmall2Web.webp") center no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.coWorkSmall1Hero {
  background-image: url("./images/CoworkSmall2Web.webp");
  background-size: cover;
  min-height: 40vh;
}
.coWorkSmall2Hero {
  min-height: calc(100vh - 66px);
  background: url("./images/CoworkSmall3Web.webp") center no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.coWorkSmall2Hero {
  background-image: url("./images/CoworkSmall3Web.webp");
  background-size: cover;
  min-height: 40vh;
}
.coWorkSmall3Hero {
  min-height: calc(100vh - 66px);
  background: url("./images/CoworkSmall1Web.webp") center no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.coWorkSmall3Hero {
  background-image: url("./images/CoworkSmall1Web.webp");
  background-size: cover;
  min-height: 40vh;
}
.cafeSmall1Hero {
  min-height: calc(100vh - 66px);
  background: url("./images/Bistro1.webp") center no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cafeSmall1Hero {
  background-image: url("./images/Bistro1.webp");
  background-size: cover;
  min-height: 40vh;
}
.cafeSmall2Hero {
  min-height: calc(100vh - 66px);
  background: url("./images/Bistro4.webp") center no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cafeSmall2Hero {
  background-image: url("./images/Bistro4.webp");
  background-size: cover;
  min-height: 40vh;
}
.cafeSmall3Hero {
  min-height: calc(100vh - 66px);
  background: url("./images/Bistro3.webp") center no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cafeSmall3Hero {
  background-image: url("./images/Bistro3.webp");
  background-size: cover;
  min-height: 40vh;
}
.cafeLargeHero {
  min-height: calc(100vh - 66px);
  background: url("./images/Bistro.webp") center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cafeLargeHero {
  background-image: url("./images/Bistro.webp");
  min-height: 60vh;
}
.aquaSmall1Hero {
  min-height: calc(100vh - 66px);
  background: url("./images/Aquap1.webp") center no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.aquaSmall1Hero {
  background-image: url("./images/Aquap1.webp");
  background-size: cover;
  min-height: 40vh;
}
.aquaSmall2Hero {
  min-height: calc(100vh - 66px);
  background: url("./images/Aquap1.webp") center no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.aquaSmall2Hero {
  background-image: url("./images/Aquap1.webp");
  background-size: cover;
  min-height: 40vh;
}
.aquaSmall3Hero {
  min-height: calc(100vh - 66px);
  background: url("./images/Aquap1.webp") center no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.aquaSmall3Hero {
  background-image: url("./images/Aquap1.webp");
  background-size: cover;
  min-height: 40vh;
}
.aquaLargeHero {
  min-height: calc(100vh - 66px);
  background: url("./images/Aquap1.webp") center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.aquaLargeHero {
  background-image: url("./images/Aquap1.webp");
  min-height: 60vh;
}
.foodLargeHero {
  min-height: calc(100vh - 66px);
  background: url("./images/momos.webp") center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.foodLargeHero {
  background-image: url("./images/momos.webp");
  min-height: 60vh;
}
.menu1LargeHero {
  min-height: calc(100vh - 66px);
  background: url("./images/GreenSpaceMenu1.webp") no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.menu1LargeHero {
  background-image: url("./images/GreenSpaceMenu1.webp");
  min-height: 75vh;
  background-size: contain;
  flex-direction: row;
}
.menu2LargeHero {
  min-height: calc(100vh - 66px);
  background: url("./images/GreenSpaceMenu2.webp") no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.menu2LargeHero {
  background-image: url("./images/GreenSpaceMenu2.webp");
  background-size: contain;
  min-height: 75vh;
  flex-direction: row;
}
.menu3LargeHero {
  min-height: calc(100vh - 66px);
  background: url("./images/GreenSpaceMenu3.webp") no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.menu3LargeHero {
  background-image: url("./images/GreenSpaceMenu3.webp");
  background-size: contain;
  min-height: 75vh;
  flex-direction: row;
}
.menu4LargeHero {
  min-height: calc(100vh - 66px);
  background: url("./images/GreenSpaceMenu4.webp") no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.menu4LargeHero {
  background-image: url("./images/GreenSpaceMenu4.webp");
  background-size: contain;
  min-height: 75vh;
  flex-direction: row;
}
.menu5LargeHero {
  min-height: calc(100vh - 66px);
  background: url("./images/GreenSpaceMenu5.webp") no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.menu5LargeHero {
  background-image: url("./images/GreenSpaceMenu5.webp");
  background-size: contain;
  min-height: 75vh;
  flex-direction: row;
}

.menu6LargeHero {
  min-height: calc(100vh - 66px);
  background: url("./images/GreenSpaceMenu6.webp") no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.menu6LargeHero {
  background-image: url("./images/GreenSpaceMenu6.webp");
  background-size: contain;
  min-height: 75vh;
  flex-direction: row;
}

.menu7LargeHero {
  min-height: calc(100vh - 66px);
  background: url("./images/GreenSpaceMenu7.webp") no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.menu7LargeHero {
  background-image: url("./images/GreenSpaceMenu7.webp");
  background-size: contain;
  min-height: 75vh;
  flex-direction: row;
}
.bloggerLargeHero {
  min-height: calc(100vh - 66px);
  background: url("./images/BloggerLargeWeb.webp") center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bloggerLargeHero {
  background-image: url("./images/BloggerLargeWeb.webp");
  min-height: 60vh;
}
.volunteerLargeHero {
  min-height: calc(100vh - 66px);
  background: url("./images/VolunteerLargeWeb.webp") center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.volunteerLargeHero {
  background-image: url("./images/VolunteerLargeWeb.webp");
  min-height: 60vh;
}
.productLargeHero {
  min-height: calc(100vh - 66px);
  background: url("./images/ProductNew2.webp") no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
}
.productLargeHero {
  background-image: url("./images/ProductNew2.webp") no-repeat;
  background-size: contain;
  min-height: 60vh;
}
.productSmall1Hero {
  min-height: calc(100vh - 66px);
  background: url("./images/ProductNew3.webp") center no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.productSmall1Hero {
  background-image: url("./images/ProductNew3.webp");
  background-size: cover;
  min-height: 40vh;
}
.productSmall2Hero {
  min-height: calc(100vh - 66px);
  background: url("./images/ProductNew4.webp") center no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.productSmall2Hero {
  background-image: url("./images/ProductNew4.webp");
  background-size: cover;
  min-height: 40vh;
}
.productSmall3Hero {
  min-height: calc(100vh - 66px);
  background: url("./images/ProductNew5.webp") center no-repeat; 
  background-size: cover;
  display: block;
  align-items: center;
  justify-content: center;
}
.productSmall3Hero {
  background-image: url("./images/ProductNew5.webp");
  background-size: cover;
  min-height: 40vh;
  object-fit: contain;
}
/* End of Hero */
/*GSImage*/
.gsImage {
  min-height: 60vh;
  display: flex;
}
/*End of GSImage*/
/* Banner */
.banner {
  display: inline-block;
  background: rgba(0, 0, 0, 0.5);
  color: var(--mainWhite);
  padding: 2rem 1rem;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}
.banner h1 {
  font-size: 2.5rem;
}
.banner div {
  width: 10rem;
  height: 5px;
  background: var(--primaryColor);
  margin: 1.7rem auto;
}
.banner p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
@media screen and (min-width: 576px) {
  .banner {
    padding: 2rem 3rem;
  }
  .banner h1 {
    font-size: 3rem;
  }
}
@media screen and (min-width: 992px) {
  .banner {
    padding: 2rem 6rem;
  }
  .banner h1 {
    font-size: 4rem;
  }
}
/* End of Banner */
/* Title */
.section-title {
  text-align: center;
  margin-bottom: 2rem;
}
.section-title h4 {
  font-size: 2rem;
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
  margin-bottom: 1rem;
}
.section-title div {
  width: 5rem;
  height: 5px;
  margin: 0 auto;
  background: var(--primaryColor);
}
/* end of Title */

/* services */
.services {
  padding: 2rem 0;
}
.services {
  background: var(--backcolor);
  text-align: center;
}
.services-alt {
  background: var(--backcolor);
  text-align: center;
}
.services-center {
  width: 90vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
  grid-row-gap: 1rem;
  grid-column-gap: 20px;
}
.service span {
  display: inline-block;
  vertical-align: middle;
  color: var(--primaryColor);
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.services h6 {
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
}
.services div {
  
  vertical-align: middle;
}
.services p {
  width: 80%;
  margin: 0 auto;
  text-align: left;
}
.service-alt span {
  display: inline-block;
  vertical-align: middle;
  color: var(--primaryColor);
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.service-icons span {
  display: inline-block;
  vertical-align: middle;
  color: var(--primaryColor);
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  padding: 5rem 0;
}

.services-icon div {
  
  vertical-align: middle;
  float: left;
  padding: 5 rem 0;
}

.services-alt h6 {
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
}
.services-alt p {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}
@media screen and (min-width: 992px) {
  .services-center {
    width: 95vw;
    max-width: 1170px;
  }
}

@media screen and (min-width: 1200px) {
  .services p {
    width: 100%;
  }
}
/*end of services */
/* featured rooms */

.featured-rooms {
  padding: 5rem 0;
}
.featured-rooms-center {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 40px;
}
@media screen and (min-width: 776px) {
  .featured-rooms-center {
    width: 90vw;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  }
}
@media screen and (min-width: 992px) {
  .featured-rooms-center {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end pf featured rooms */
/* room */
.room {
  box-shadow: var(--lightShadow);
  transition: var(--mainTransition);
}
.room:hover {
  box-shadow: var(--darkShadow);
}

.img-container {
  position: relative;
}
.img-container img {
  width: 100%;
  display: block;
  transition: var(--mainTransition);
}
.price-top {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  color: var(--mainWhite);
  padding: 0.3rem 0.6rem 0.5rem;
  border-bottom-right-radius: 1rem;
  font-size: 0.5rem;
  text-align: center;
  transition: var(--mainTransition);
}
.price-top h6 {
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: var(--mainSpacing);
}
.room-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(0);
  transition: all 0.3s linear;
}
.img-container:hover {
  background: rgba(0, 0, 0, 0.8);
}
.img-container:hover img {
  opacity: 0.3;
}
.img-container:hover .price-top {
  opacity: 0;
}
.img-container:hover .room-link {
  transform: translate(-50%, -50%) scale(1);
}
.room-info {
  background: var(--darkGrey);
  text-transform: capitalize;
  padding: 0.5rem 0;
  text-align: center;
  font-weight: 700;
  letter-spacing: var(--mainSpacing);
}
/* end of room  */
/* single room*/

.single-room {
  padding: 5rem 0 0 0;
}
.single-room-images {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;
}
.single-room-images img {
  width: 100%;
  display: block;
}
.single-room-info {
  width: 80vw;
  display: grid;
  grid-template-columns: 1fr;
  margin: 2rem auto;
}
.desc,
.info {
  margin: 1rem 0;
}
.desc h3 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}
.desc p {
  line-height: 1.5;
}
.info h3,
.info h6 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}

.info h6 {
  font-weight: 300;
}
.room-extras {
  width: 80vw;
  margin: 0 auto 3rem auto;
}
.room-extras h6 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}
.extras {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
}
@media screen and (min-width: 992px) {
  .single-room-images,
  .single-room-info,
  .room-extras {
    width: 95vw;
    max-width: 1170px;
  }
  .single-room-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
  .info {
    padding-left: 3rem;
  }
}
/* end of single room*/
/* roomlist */
.roomslist {
  padding: 5rem 0;
}
.roomslist-center {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 30px;
}

@media screen and (min-width: 776px) {
  .roomslist-center {
    width: 90vw;
  }
}
@media screen and (min-width: 992px) {
  .roomslist-center {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end of roomlist */
/*  rooms fitler*/
.filter-container {
  padding: 5rem 0;
}
.filter-form {
  width: 60vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(202px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 40px;
}
.form-group {
  text-transform: capitalize;
}
.form-group label {
  display: block;
  letter-spacing: var(--mainSpacing);
  margin-bottom: 0.5rem;
}
.form-control {
  width: 100%;
  background: transparent;
  font-size: 1rem;
}
.size-inputs {
  display: flex;
}
.size-input {
  width: 40%;
  padding: 0.2rem;
  border: 1px solid var(--mainBlack);
  border-radius: 0.3rem;
  margin-right: 0.3rem;
}
.single-extra label {
  display: inline-block;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}
@media screen and (min-width: 776px) {
  .filter-form {
    width: 70vw;
  }
}
@media screen and (min-width: 992px) {
  .filter-form {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end of rooms fitler*/

.div1 div{

display: flex;
}

.social-container {
  background: var(--menucolor);
  padding: 0px 50px;
  vertical-align: middle;
}

.social-container-alt {
  padding: 20px 20px;
  vertical-align: middle;
}

a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
  vertical-align: middle
}

a.social:hover {
  transform: translateY(-2px);
}

a.google {
  color: #856b39;
}

a.facebook {
  color: #856b39;
}

a.tripadvisor {
  color: #856b39;
}

a.instagram {
  color: #856b39;
}

a.booking {
  color: #856b39;
}

.cowork-list {
  list-style-type: none;
  padding-left: 0rem; 
}